<template>
  <BlockContainer>
    <div class="flex flex-wrap p-4">
      <BlockWideThumbnail
        v-for="(item, i) in orderedCompetitionPosts"
        :key="i"
        v-bind="item"
        :dateFormater="dateFormater"
        @click="
          $router.push({ name: 'CompetitionItem', params: { slug: item.slug } })
        "
      />
    </div>
  </BlockContainer>
</template>

<script>
import { getCompetitions } from "@io";
import { BlockContainer, BlockWideThumbnail } from "@c/Block";

export default {
  name: "Blog",

  components: {
    BlockContainer,
    BlockWideThumbnail
  },

  data: () => ({
    data: null
  }),

  computed: {
    orderedCompetitionPosts() {
      if (!this.data) return;
      return [...this.data].sort((a, b) => new Date(b.date) - new Date(a.date));
    }
  },

  async created() {
    this.data = await getCompetitions();
  },

  methods: {
    dateFormater(strDate) {
      const date = new Date(strDate);

      const dateTimeFormat = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "long"
      });

      const [, , { value: year }] = dateTimeFormat.formatToParts(date);

      return `${year}`;
    }
  }
};
</script>
